import React, { useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';

const SubmissionsTable = () => {
    const [rows, setRows] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(50);

    let columns = [
        {
            field: 'firstname',
            headerName: 'First Name',
            headerClassName: 'submission-table-header',

            width: 150,
        },
        {
            field: 'lastname',
            headerName: 'Last Name',
            headerClassName: 'submission-table-header',
            width: 150,
        },
        {
            field: 'species',
            headerName: 'Species',
            headerClassName: 'submission-table-header',

            width: 150,
        },
        {
            field: 'length',
            headerName: 'Length',
            headerClassName: 'submission-table-header',

            width: 100,
        },
        {
            field: 'timestamp',
            headerName: 'Date',
            headerClassName: 'submission-table-header',

            width: 150,
        },
        { field: 'divestyle', headerName: 'Dive' },
        { field: 'division', headerName: 'Division' },
    ];

    const handlePageSizeChange = (params) => {
        setPageSize(params.pageSize);
    };

    useEffect(() => {
        fetch('/api/allsubmissions/all')
            .then((response) => response.json())
            .then((responsedata) => {
                let submissions = responsedata.response;
                console.log(submissions);
                setRows(submissions);
            });
    }, []);

    return (
        <div>
            <section id="page" class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h2>All Submissions 2024</h2>
                        </div>
                    </div>
                </div>
            </section>

            <div id="about" class="section-padding">
                <div class="container">
                    <div style={{ height: 900, width: '100%' }}>
                        <DataGrid
                            pageSize={pageSize}
                            rows={rows}
                            sortModel={[
                                {
                                    field: 'timestamp',
                                    sort: 'desc',
                                },
                            ]}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            rowsPerPageOptions={[50, 100, 200]}
                            pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmissionsTable;

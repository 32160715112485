import React, { useState } from 'react';
import SponsorModal from './SponsorModal';
import SponsorItem from './SponsorItem';
import SponsorItemLarge from './SponsorItemLarge';

export const TopDogSponsors = [
    {
        title: 'Neritic Diving',
        description: '$750 store credit',
        divestyle: 'freedive',
        divisionType: 'open',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Seefish Charters',
        description: '',
        divestyle: 'scuba',
        divisionType: 'women',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Dixie Divers',
        description: '',
        species: 'Cubera',
        divestyle: 'scuba',
        divisionType: 'open',
        logo: '',
        instagram: '',
        facebook: 'https://www.facebook.com/dixiedivers/',
        youtube: '',
        website: 'https://www.dixiediver.com/',
    },
    {
        title: 'Neritic - $500',
        description: '',
        divestyle: 'freedive',
        divisionType: 'women',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'Jason Bihari Taxidermy',
        description: '',
        species: 'Cubera',
        divestyle: 'scuba',
        divisionType: 'junior',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'FL Freedivers $500 (education)',
        description: '',
        species: 'Cubera',
        divestyle: 'freedive',
        divisionType: 'junior',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'Juan Serrano $500 Cash',
        description: '',
        species: 'Cubera',
        divestyle: 'freedive',
        divisionType: 'senior',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'Neritic Diving $500 Credit',
        description: '',
        species: 'Cubera',
        divestyle: 'scuba',
        divisionType: 'senior',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'Neritic Diving $500 Credit',
        description: '',
        species: 'Cubera',
        divestyle: 'scuba',
        divisionType: 'shoredive',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'Neritic Diving $500 Credit',
        description: '',
        species: 'Cubera',
        divestyle: 'freedive',
        divisionType: 'shoredive',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
];

export const FreediveSponsorsList = [
    {
        title: 'Red Tide Spearfishing',
        description:
            'Red Tide Spearfishing has been providing our spearfishing community with quality spearfishing products since 2008. All of our products have been developed and are being used by top spearfisherman around the world. You will find many of our products being used as OEM parts for some of the top names in the business. We continually strive to bring you the best quality spearfishing gear and the most competitive prices available. Whether you are a spearfishing dive shop owner, a custom speargun builder, or a serious spearfisherman, you will find most everything you need right here in our online sales center.',
        category: 'freedive',
        species: 'African Pompano',
        website: 'https://redtidespearfishing.com/',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Fish Sticks Spearguns',
        description: '',
        category: 'freedive',
        species: 'Amberjack',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'BOTE',
        description: '',
        category: 'freedive',
        species: 'Cobia',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Ocean Ammo Spearguns',
        description: '',
        category: 'freedive',
        species: 'Cubera',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Surf Fur',
        description: '',
        category: 'freedive',
        species: 'Grouper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Waihana',
        description:'',
        category: 'freedive',
        species: 'Kingfish',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Riffe',
        description:
            "All divers should protect the waters they swim and hunt in, ensuring future divers the experience & excitement of freediving that I've had over the past 68 years. Selective Divers set their sights on those few prize fish for consumption. BE SELECTIVE, FISH FOR THE FUTURE.",
        category: 'freedive',
        species: 'Hogfish',
        website: 'http://www.speargun.com',
        instagram: '',
        facebook: '',
    },
    {
        title: 'El Brocko Spearguns',
        description: '',
        category: 'freedive',
        species: 'Mahi',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Koah Spearguns',
        description: '',
        category: 'freedive',
        species: 'Mangrove Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Free-Spear-It',
        description: '',
        category: 'freedive',
        species: 'Mutton Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Neptonics Tampa',
        description:
            'Neptonics offers the best spearfishing gear, spearguns, carbon fins, speargun parts and spearfishing packages at the lowest prices.',
        category: 'freedive',
        species: 'Permit',
        website: 'https://neptonics.com/',
        instagram: 'https://www.instagram.com/neptonicstampa',
        facebook: 'https://www.facebook.com/NeptonicsTampa',
    },
    {
        title: 'Kinetic Spearguns',
        description: '',
        category: 'freedive',
        species: 'Wahoo',
        website: 'https://www.killshotspearguns.com/',
        instagram: '',
        facebook: '',
    },
    {
        title: 'SoFlo Spearfishing',
        description: '',
        category: 'freedive',
        species: 'Sheepshead',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Anhiga Spearguns',
        description: '',
        category: 'freedive',
        species: 'Red Snapper',
        website: 'anhiga12',
        instagram: '',
        facebook: 'https://www.anihgacharters.com',
    },
    {
        title: 'Moudiq',
        description: '',
        category: 'freedive',
        species: 'Lobster',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Moudiq',
        description: '',
        category: 'freedive',
        species: 'Lionfish Count',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: '**Mystery Announce**',
        description: '',
        species: 'Rainbow Runner',
        divestyle: 'freedive',
        logo: '',
        instagram: '',
        facebook: '',
        youtube: '',
        website: '',
    },
    {
        title: 'TC Spearguns',
        description: '',
        category: 'freedive',
        species: 'Yellowjack',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Above & Below Adventures',
        description: '',
        category: 'freedive',
        species: 'Yellowtail Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
];

export const ScubaSponsorsList = [
    {
        title: 'Brian Kelly Fish Art',
        description: '',
        category: 'scuba',
        species: 'African Pompano',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Reliable Fish Bags',
        description: '',
        category: 'scuba',
        species: 'Amberjack',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Ocean Hunter',
        description:
            "Ocean Hunter is New Zealand's 100% dedicated spearfishing and freediving specialist. A great place to shop for all your spearfishing gear and free diving equipment.",
        category: 'scuba',
        species: 'Cobia',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Rob Allen',
        description:
            "Rob Allen has been manufacturing spearfishing gear since 1982. His guns have grown in popularity and due to their simplicity and strength can withstand almost any water conditions. Today the brand is one of the world's most popular and now has a distribution network covering more than 40 countries.",
        category: 'scuba',
        species: 'Cubera',
        website: 'https://roballendiving.com',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Ocean State Spearguns',
        description: '',
        category: 'scuba',
        species: 'Black Grouper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Texas Bluewater Spearguns',
        description: '',
        category: 'scuba',
        species: 'Red Grouper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Mangrove Marine',
        description: '',
        category: 'scuba',
        species: 'Hogfish',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Mangrove Marine',
        description: '',
        category: 'scuba',
        species: 'Mangrove Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Lobster King',
        description: '',
        category: 'scuba',
        species: 'Lobster',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Neritic Diving',
        description:
            'Neritic Diving designs, manufactures, and assembles quality equipment that will never let you down. In addition, we offer impeccable customer service and are almost always available for any questions or interest you may have in our products. Being available for phone calls, text messages, social media communication, or emails, provides an intimate customer experience, including the swapping of fish stories!',
        category: 'scuba',
        species: 'Lionfish Biggest',
        website: 'https://neriticdiving.com',
        instagram: '',
        facebook: 'https://www.facebook.com/Neriticdiving/',
    },
    {
        title: 'Florida Telecaster Charters',
        description:'',
        category: 'scuba',
        species: 'Sheepshead',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Force-E',
        description:'',
         category: 'scuba',
        species: 'Red Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Deep Six',
        description: '',
        category: 'scuba',
        species: 'Triggerfish',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Wrecked Charters',
        description: '',
        category: 'scuba',
        species: 'Permit',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'South Florida Grassing Inc.',
        description: '',
        category: 'scuba',
        species: 'Yellowjack',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Bobby Sullivan - First Aid Kit',
        description:'',
        category: 'scuba',
        species: 'Scamp Grouper',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Moudiq',
        description: '',
        category: 'scuba',
        species: 'Lionfish Count',
        website: '',
        instagram: '',
        facebook: '',
    },
    {
        title: 'Seefish Charters',
        description: '',
        category: 'scuba',
        species: 'Mutton Snapper',
        website: '',
        instagram: '',
        facebook: '',
    },
];

const Sponsors = () => {
    const [modalSponsor, setModalSponsor] = useState({});

    const renderTopDogSponsors = () => {
        return (
            <div id="sponsorsIndex">
                <div class="container">
                    <div class="section-title text-center">
                        <h3>Topdog Sponsors</h3>
                    </div>
                    <div class="row text-center">
                        <SponsorItemLarge
                            title={'Florida Freediver $1000 credit'}
                            species={'1st Freedive Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Dixie Divers - $750 credit'}
                            species={'1st Scuba Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Gulf Coast Alumium - $500'}
                            species={'2nd Freedive Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Reliable Fish Bags - Fish Bag'}
                            species={'2nd Scuba Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'FL Fish Prints'}
                            species={'3rd Freedive Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Seefish Charters'}
                            species={'3rd Scuba Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Florida Freedivers - $500 education'}
                            species={'Junior Freedive'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Jason Bihari'}
                            species={'Junior Scuba'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Wescon Construction - $1000 cash'}
                            species={'1st Freedive Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Neritic Diving'}
                            species={'1st Scuba Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Nautilus - Wetsuit'}
                            species={'2nd Freedive Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Merseas Company - $250'}
                            species={'2nd Scuba Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Deep Apnea'}
                            species={'3rd Freedive Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Seefish Charters'}
                            species={'3rd Scuba Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Wahaina'}
                            species={'4th Freedive Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'MANG Gear - $250'}
                            species={'4th Scuba Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Julies Hand Drawn Art'}
                            species={'5th Freedive Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'FL Fish Prints'}
                            species={'5th Scuba Women'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Juan Serrano - $500 Cash'}
                            species={'Senior Freedive Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Neritic Diving - $500 Credit'}
                            species={'Senior Scuba Open'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Dexter - Free Knife'}
                            species={'Freedive Open Top 12'}
                        ></SponsorItemLarge>
                        <SponsorItemLarge
                            title={'Dexter - Free Knife'}
                            species={'Scuba Open Top 12'}
                        ></SponsorItemLarge>
                    </div>
                </div>
            </div>
        );
    };
    const renderFreediveSponsors = () => {
        return (
            <div class="row text-center">
                {FreediveSponsorsList.map((sponsor) => (
                    <SponsorItem
                        sponsor={sponsor}
                        setModalSponsor={setModalSponsor}
                    ></SponsorItem>
                ))}
            </div>
        );
    };

    const renderScubaSponsors = () => {
        return (
            <div class="row text-center">
                {ScubaSponsorsList.map((sponsor) => (
                    <SponsorItem
                        sponsor={sponsor}
                        setModalSponsor={setModalSponsor}
                    ></SponsorItem>
                ))}
            </div>
        );
    };

    return (
        <div>
            <div>
                <section id="page" class="page-heading">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 text-left">
                                <h2>Sponsors and Prizes</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {renderTopDogSponsors()}
                <div id="freedive" class="section-padding">
                    <div class="container">
                        <div class="row text-center">
                            <div class="section-title text-center">
                                <h3>Freedive Sponsors</h3>
                            </div>
                        </div>
                        {renderFreediveSponsors()}
                    </div>
                </div>

                <div id="scuba" class="section-padding">
                    <div class="container">
                        <div class="row text-center">
                            <div class="section-title text-center">
                                <h3>Scuba Sponsors</h3>
                            </div>
                        </div>
                        {renderScubaSponsors()}
                    </div>
                </div>
            </div>

            <SponsorModal sponsor={modalSponsor}></SponsorModal>
        </div>
    );
};

export default Sponsors;

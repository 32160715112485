import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//...

const Diver = () => {
    const { diverId } = useParams();

    const [diverSubmissions, setDiverSubmissions] = useState([]);
    const [diver, setDiver] = useState({});

    useEffect(() => {
        fetch('/api/allsubmissions/order/' + diverId)
            .then((response) => response.json())
            .then((responsedata) => {
                let diversData = responsedata.response;
                console.log(diversData);
                setDiver(diversData[0]);
                setDiverSubmissions(diversData);
            });
    }, [diverId]);

    return (
        <div>
            <section id="page" class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h2>
                                {diver.firstname} {diver.lastname}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div id="about" class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="section-title text-center">
                            <h2>Diver ID = {diverId}</h2>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div className="diver-submissions-title text-center">
                            <h5
                                className="text-center"
                                data-toggle="modal"
                                data-target="#sponsorModal"
                                ng-click="setModalSponsor(topDogSponsor)"
                            >
                                Submissions
                            </h5>
                        </div>
                        <div className="table-responsive col-md-12 leaderboard-tablefafafacecod">
                            <table className="diver-submissions-table table">
                                <thead>
                                    <tr style={{ color: '#235D93' }}>
                                        <th></th>
                                        <th>Time</th>
                                        <th>Species</th>
                                        <th>Length</th>
                                        <th>Name</th>
                                        <th>Style</th>
                                        <th>Division</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {diverSubmissions.map(
                                        (submission, index) => {
                                            return (
                                                <tr ng-repeat="diver in topDogData">
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {submission.timestamp}
                                                    </td>

                                                    <td>
                                                        {submission.species}
                                                    </td>
                                                    <td>{submission.length}</td>
                                                    <td>
                                                        {submission.firstname}{' '}
                                                        {submission.lastname}
                                                    </td>
                                                    <td>
                                                        {submission.divestyle}
                                                    </td>
                                                    <td>
                                                        {submission.division}
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Diver;

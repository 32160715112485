import React from 'react';

const SponsorItemLarge = (props) => {
    const { title, species, category } = props;
    return (
        <div
            class="col-md-6 sponsor-panel"
            data-toggle="modal"
            data-target="#sponsorModal"
        >
            <div class="single-team-page">
                <div class="sponsors-img"></div>
                <div class="team-add">
                    <h4>{title}</h4>
                    <h5>{species}</h5>
                    <h6>{category}</h6>
                </div>
                <div class="team-dec"></div>
                <div class="member-social">
                    <ul class="social-links clearfix"></ul>
                </div>
            </div>
        </div>
    );
};

export default SponsorItemLarge;

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const SubmissionToApprove = (props) => {
    let {
        submissionId,
        firstname,
        lastname,
        species,
        length,
        division,
        divestyle,
        fishstory,
        order,
        fetchSubmissions,
    } = props;

    function handleDiverUpdate() {
        const url = '/api/' + divestyle + '/order/' + order;

        fetch(url).then(
            function (response) {
                console.log('success: ', response.data);

                if (response.status === 404) {
                    //update record
                    console.log('user doesnt exist posting new user');
                    const diverUrl = '/api/' + divestyle;
                    const postData = {
                        order: order,
                        firstname: firstname,
                        lastname: lastname,
                        division: division,
                    };
                    postData[species] = length;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(postData),
                    };
                    fetch(diverUrl, requestOptions).then(
                        function (response) {
                            console.log(
                                'success creating new user: ',
                                response,
                            );
                            approveSubmission();
                            return true;
                        },
                        function (response) {
                            console.log(
                                'failure creating new user: ',
                                response,
                            );
                            return false;
                        },
                    );
                }

                if (response.status === 200) {
                    //update record

                    console.log('user exists updating user', response);

                    response.json().then((body) => {
                        const id = body.response.id;
                        const diverUrl = '/api/' + divestyle + '/' + id;
                        const postData = {
                            id: id,
                            division: division,
                            firstname: firstname,
                            lastname: lastname,
                        };
                        postData[species] = length;
                        const requestOptions = {
                            method: 'PUT',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(postData),
                        };
                        fetch(diverUrl, requestOptions).then(
                            function (response) {
                                console.log(
                                    'success updating user: ',
                                    response,
                                );
                                approveSubmission();
                                return true;
                            },
                            function (response) {
                                console.log(
                                    'failure updating user: ',
                                    response,
                                );
                                return false;
                            },
                        );
                    });
                }
            },
            function (response) {
                console.log('failure: ', response);
                return false;
            },
        );
    }

    function approveSubmission() {
        const postData = { approved: 1, reviewed: 1 };
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };
        const url = '/api/allsubmissions/' + submissionId;
        fetch(url, requestOptions).then(
            function (response) {
                toast.success('Submission Approved', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                console.log(response);
                fetchSubmissions();
                return true;
            },
            function (response) {
                console.log('Error: ');
                console.log(response);
                return false;
            },
        );
    }

    function denySubmission(callback) {
        const postData = { approved: 0, reviewed: 1 };
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };
        const url = '/api/allsubmissions/' + submissionId;
        fetch(url, requestOptions).then(
            function (response) {
                toast.warning('Submission denied successfully', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                console.log(response);
                fetchSubmissions();
                return true;
            },
            function (response) {
                console.log('Error denying submission');
                console.log(response);
                return false;
            },
        );
    }

    function handleApprove() {
        handleDiverUpdate();
    }

    function handleDeny() {
        denySubmission();
    }

    return (
        <div class="col-md-4 col-sm-6" ng-repeat="submission in submissions">
            <div class="services-t">
                <h4>
                    {firstname} {lastname}
                </h4>
                <p>
                    Species: <b>{species}</b>
                </p>
                <p>
                    Length: <b>{length}</b>
                </p>
                <p>
                    Division: <b>{division}</b>
                </p>
                <p>Type of diving: {divestyle}</p>
                <span></span>

                <p>Story: {fishstory}</p>
                <p ng-show="submission.pictureone !== 'pathToOne'">
                    <a ng-href="{{submission.pictureone}}">1st picture</a>
                </p>
                <p ng-show="submission.picturetwo !== 'pathToTwo'">
                    <a ng-href="{{submission.picturetwo}}">2nd picture</a>
                </p>
                <p ng-show="submission.picturethree !== 'pathToThree'">
                    <a ng-href="{{submission.picturethree}}">3rd picture</a>
                </p>
                <span></span>
                <div class="row text-center">
                    <div class="col-md-4 col-sm-4 col-sm-offset-2">
                        <div class="actions">
                            <input
                                type="submit"
                                value="Approve"
                                class="btn btn-lg btn-contact-bg"
                                onClick={handleApprove}
                            />
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="actions">
                            <input
                                type="submit"
                                value="Deny"
                                class="btn btn-lg btn-contact-bg"
                                onClick={handleDeny}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Admin = (props) => {
    const [unreviewedSubmissions, setUnreviewedSubmissions] = useState([]);

    useEffect(() => {
        fetchSubmissions();
    }, []);

    const fetchSubmissions = () => {
        fetch('/api/allsubmissions/all')
            .then((response) => response.json())
            .then((responsedata) => {
                let currentSubmissions = filterCurrentSubmissions(
                    responsedata.response,
                );
                sortBySubmissionTotal(currentSubmissions);
                let reviewedSubmissions = filterReviewedSubmissions(
                    currentSubmissions,
                );
                console.log('submissions: ', reviewedSubmissions);
                setUnreviewedSubmissions(reviewedSubmissions);
            });
    };

    const sortBySubmissionTotal = (submissions) => {
        console.log('sorting submission totals');
        let divers = {};
        for (var i = 0; i < submissions.length; i++) {
            if (divers.hasOwnProperty([submissions[i].order])) {
                divers[submissions[i].order]++;
            } else {
                divers[submissions[i].order] = 0;
            }
        }
        console.log(divers);
    };

    const filterCurrentSubmissions = (submissions) => {
        let currentSubmissions = getCurrentYearSubmissions(submissions);
        return currentSubmissions.filter((submission) => {
            return submission;
        });
    };

    const getCurrentYearSubmissions = (submissions) => {
        return submissions.filter((submission) => {
            let submissionDate = new Date(submission.timestamp);
            const startDate = new Date('2024-06-01');
            return submissionDate > startDate;
        });
    };

    const filterReviewedSubmissions = (submissions) => {
        return submissions.filter((submission) => {
            return submission.reviewed === 0;
        });
    };

    return (
        <>
            <section id="page" class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h2>Approval</h2>
                        </div>
                    </div>
                </div>
            </section>

            <div id="contactFrom bg-gray">
                <div class="container section-padding ">
                    <div class="row">
                        <div class="section-title text-center">
                            <h6>
                                Directly modifies an order numbers length on a
                                specific species.
                            </h6>
                            <h2>Admin Edit</h2>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                            <div class="contact"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="services" class="section-padding bg-gray">
                <div class="container">
                    <div class="row">
                        <div class="section-title text-center">
                            <h6>TO BE REVIEWED</h6>
                            <h2>Submissions Pending</h2>
                            <div class="line"></div>

                            {unreviewedSubmissions.map((row, i) => {
                                return (
                                    <SubmissionToApprove
                                        key={i}
                                        firstname={row.firstname}
                                        lastname={row.lastname}
                                        species={row.species}
                                        length={row.length}
                                        division={row.division}
                                        submissionId={row.id}
                                        divestyle={row.divestyle}
                                        order={row.order}
                                        fishstory={row.fishstory}
                                        fetchSubmissions={fetchSubmissions}
                                    ></SubmissionToApprove>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                closeOnClick
            ></ToastContainer>
        </>
    );
};

export default Admin;

import React from 'react';
const SponsorModal = (props) => {
    console.log('sponsor modal props: ', props);
    let { sponsor } = props;
    return (
        <div id="sponsorModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                        >
                            &times;
                        </button>
                        <h3 class="col-sm-4 sponsor-modal-title text-center">
                            {sponsor.title}
                        </h3>
                        <div class="col-sm-4 smember-social">
                            <ul class="social-links clearfix">
                                {sponsor.instagram && (
                                    <li ng-show="sponsorModalData.instagram !== ''">
                                        <a
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Facebook"
                                            class="social-ig"
                                            href={sponsor.instagram}
                                        >
                                            <span class="fa fa-instagram"></span>
                                        </a>
                                    </li>
                                )}
                                {sponsor.facebook && (
                                    <li ng-show="sponsorModalData.facebook !== ''">
                                        <a
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Facebook"
                                            class="social-fb"
                                            href={sponsor.facebook}
                                        >
                                            <span class="fa fa-facebook"></span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="sponsor-modal-header row">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4">
                                <h5 class="sponsor-category text-center">
                                    {sponsor.category} {sponsor.species}
                                </h5>
                            </div>
                        </div>

                        <div class="sponsor-modal-content">
                            <p>{sponsor.description} </p>
                            {sponsor.website && (
                                <a
                                    type="button"
                                    href={sponsor.website}
                                    class="sponsor-site-button btn btn-default"
                                >
                                    View Site
                                </a>
                            )}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-default"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SponsorModal;

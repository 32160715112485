/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import LionfishLeaderboard from './LionfishLeaderboard';
import LeaderboardMain from './LeaderboardTable';
import TopFive from './TopFive';

const TabMenu = (props) => {
    let { divisionType, setDivisionType } = props;
    return (
        <ul className="nav nav-tabs" role="tablist">
            <li
                role="presentation"
                className={divisionType === 'open' ? 'active' : ''}
            >
                <a
                    aria-controls="two"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setDivisionType('open')}
                >
                    Open
                </a>
            </li>
            <li
                role="presentation"
                className={divisionType === 'women' ? 'active' : ''}
            >
                <a
                    aria-controls="three"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setDivisionType('women')}
                >
                    Women
                </a>
            </li>
            <li
                role="presentation"
                className={divisionType === 'junior' ? 'active' : ''}
            >
                <a
                    aria-controls="four"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setDivisionType('junior')}
                >
                    Junior
                </a>
            </li>
            <li
                role="presentation"
                className={divisionType === 'senior' ? 'active' : ''}
            >
                <a
                    aria-controls="four"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setDivisionType('senior')}
                >
                    Senior
                </a>
            </li>
            <li
                role="presentation"
                className={divisionType === 'shoredive' ? 'active' : ''}
            >
                <a
                    aria-controls="four"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setDivisionType('shoredive')}
                >
                    Shore Dive
                </a>
            </li>
        </ul>
    );
};

const LionfishTabMenu = (props) => {
    let { lionfishType, setLionfishType } = props;
    return (
        <ul className="nav nav-tabs" role="tablist">
            <li
                role="presentation"
                className={lionfishType === 'biggest' ? 'active' : ''}
            >
                <a
                    aria-controls="two"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => setLionfishType('biggest')}
                >
                    Biggest
                </a>
            </li>
        </ul>
    );
};

const LeaderboardHeading = () => {
    const state = {
        divisionTitleExtension: '',
    };

    return (
        <section id="page" className="page-heading">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 text-left">
                        <h2>
                            Leaderboard
                            <span ng-hide="team">
                                {state.divisionTitleExtension}
                            </span>
                        </h2>
                    </div>
                </div>
                <p class="leaderboardInfo">
                    <ul>
                        <li>
                            Women and Junior are automatically grouped into
                            Open.
                        </li>
                        <li>
                            Click a top dog row to view all of a diver's
                            submissions.
                        </li>
                    </ul>
                </p>
            </div>
        </section>
    );
};

const LeaderboardTabMenu = ({ handleLeaderboardChange, leaderboardType }) => {
    return (
        <div className="row text-center">
            <div className="col-md-12">
                <div className="portfolio-filter-menu wow fadeInUp">
                    <ul>
                        <li
                            className={
                                'filter ' +
                                (leaderboardType === 'freedive' ? 'active' : '')
                            }
                            onClick={() => handleLeaderboardChange('freedive')}
                        >
                            Freedive
                        </li>
                        <li
                            className={
                                'filter ' +
                                (leaderboardType === 'scuba' ? 'active' : '')
                            }
                            onClick={() => handleLeaderboardChange('scuba')}
                        >
                            Scuba
                        </li>
                        <li
                            className={
                                'filter ' +
                                (leaderboardType === 'lionfish' ? 'active' : '')
                            }
                            onClick={() => handleLeaderboardChange('lionfish')}
                        >
                            Lionfish
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Leaderboard = () => {
    const state = {
        topTenData: [],
        tournamentOn: true,
    };

    const [leaderboardType, setLeaderboardType] = useState('freedive');
    const [divisionType, setDivisionType] = useState('open');
    const [lionfishType, setLionfishType] = useState('biggest');
    const [currentSubmissions, setCurrentSubmissions] = useState([]);

    useEffect(() => {
        fetch('/api/allsubmissions/all')
            .then((response) => response.json())
            .then((responsedata) => {
                let submissions = filterCurrentApprovedSubmissions(
                    responsedata.response,
                );
                setCurrentSubmissions(submissions);
            });
    }, []);

    const filterCurrentApprovedSubmissions = (submissions) => {
        let currentSubmissions = getCurrentYearSubmissions(submissions);
        return currentSubmissions.filter((submission) => {
            return submission.approved === 1;
        });
    };

    const getCurrentYearSubmissions = (submissions) => {
        return submissions.filter((submission) => {
            let submissionDate = new Date(submission.timestamp);
            const startDate = new Date('2024-06-01');
            return submissionDate > startDate;
        });
    };

    const filterByTypeAndDivision = (currentSubmissions) => {
        return currentSubmissions
            .filter((submission) => {
                return (
                    submission.division === divisionType ||
                    divisionType === 'open'
                );
            })
            .filter((submission) => {
                return submission.divestyle === leaderboardType;
            });
    };

    const NoResults = () => {
        return (
            <div className="text-center">
                <h3>No entries have been approved</h3>
            </div>
        );
    };

    return (
        <div>
            <LeaderboardHeading></LeaderboardHeading>
            <div className="section-padding">
                <div className="container">
                    <LeaderboardTabMenu
                        leaderboardType={leaderboardType}
                        handleLeaderboardChange={setLeaderboardType}
                        lionfishType={lionfishType}
                        setLionfishType={setLionfishType}
                    ></LeaderboardTabMenu>
                    <div className="row product-tabs">
                        {leaderboardType === 'lionfish' ? (
                            <LionfishTabMenu
                                setLionfishType={setLionfishType}
                            ></LionfishTabMenu>
                        ) : (
                            <TabMenu
                                divisionType={divisionType}
                                setDivisionType={setDivisionType}
                            ></TabMenu>
                        )}
                        <LeaderboardMain
                            filteredSubmissions={filterByTypeAndDivision(
                                currentSubmissions,
                            )}
                            submissions={currentSubmissions}
                            leaderboardType={leaderboardType}
                            divisionType={divisionType}
                        ></LeaderboardMain>
                    </div>
                    <div className="row">
                        <LionfishLeaderboard
                            leaderboardType={leaderboardType}
                            lionfishType={lionfishType}
                            submissions={currentSubmissions}
                        ></LionfishLeaderboard>
                        <TopFive
                            leaderboardType={leaderboardType}
                            divisionType={divisionType}
                            submissions={filterByTypeAndDivision(
                                currentSubmissions,
                            )}
                        ></TopFive>
                        {!state.tournamentOn && <NoResults></NoResults>}
                    </div>
                </div>
            </div>
            <section className="section-padding bg-gray call-to-action">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a
                                className="btn call-to-action-btn"
                                ui-sref="submit"
                            >
                                Submit a fish{' '}
                                <i className="fa fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Leaderboard;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import masterSpeciesList from '../../constants';
import {
    ScubaSponsorsList,
    FreediveSponsorsList,
    TopDogSponsors,
} from '../Sponsors';
import { useHistory } from 'react-router-dom';
import SponsorModal from '../SponsorModal';

const TeamRow = (props) => {
    let { diverOne, diverTwo, teamName, diverOneTotal, diverTwoTotal } = props;
    let teamTotal = 0;
    if (diverTwoTotal) {
        teamTotal = (diverOneTotal + diverTwoTotal) / 2;
    } else {
        teamTotal = diverOneTotal;
    }
    return (
        <tr ng-repeat="species in leaderboardData">
            <td>{diverOne}</td>
            <td>{diverOneTotal}</td>
            <td>{diverTwo}</td>
            <td>{diverTwoTotal}</td>
            <td>{teamTotal}</td>
        </tr>
    );
};

const LeaderboardRow = (props) => {
    let {
        species,
        firstname,
        lastname,
        length,
        leaderboardType,
        setModalSponsor,
    } = props;
    let sponsorList = [];
    let sponsor = {};
    if (leaderboardType === 'freedive') {
        sponsorList = FreediveSponsorsList.filter((freediveSponsor) => {
            return freediveSponsor.species === species;
        });
    } else {
        sponsorList = ScubaSponsorsList.filter((scubaSponsor) => {
            return scubaSponsor.species === species;
        });
    }

    if (sponsorList.length > 0) {
        sponsor = sponsorList[0];
    }

    return (
        <tr ng-repeat="species in leaderboardData">
            <td>
                <a
                    style={{ cursor: 'pointer' }}
                    data-toggle="modal"
                    data-target="#sponsorModal"
                    onClick={() => setModalSponsor(sponsor)}
                >
                    {sponsor.title}
                </a>
            </td>
            <td>{species}</td>
            <td>
                {firstname} {lastname}
            </td>
            <td>{length}</td>
        </tr>
    );
};

const TopDogRow = (props) => {
    let { rank, name, speciesCount, count, onDiverClick } = props;
    return (
        <tr ng-repeat="diver in topDogData" onClick={onDiverClick}>
            <th scope="row">{rank + 1}</th>
            <td>
                <a>{name}</a>
            </td>
            <td>{speciesCount}</td>
            <td>{count}</td>
        </tr>
    );
};

const LeaderboardMain = (props) => {
    let {
        leaderboardType,
        filteredSubmissions,
        divisionType,
        submissions,
    } = props;

    const [topDogData, setTopDogData] = useState([]);
    const [shoreDiveData, setShoreDiveData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [modalSponsor, setModalSponsor] = useState({});
    // declare the async data fetching function
    const fetchOpenData = useCallback(async () => {
        const openDataRaw = await fetch(
            '/api/' + leaderboardType + '/division/open',
        );
        const openDataResponse = await openDataRaw.json();
        const openData = openDataResponse.response;

        const womenDataRaw = await fetch(
            '/api/' + leaderboardType + '/division/women',
        );
        const womenDataResponse = await womenDataRaw.json();
        const womenData = womenDataResponse.response;

        const juniorDataRaw = await fetch(
            '/api/' + leaderboardType + '/division/junior',
        );
        const juniorDataResponse = await juniorDataRaw.json();
        const juniorData = juniorDataResponse.response;

        const seniorDataRaw = await fetch(
            '/api/' + leaderboardType + '/division/senior',
        );
        const seniorDataResponse = await seniorDataRaw.json();
        const seniorData = seniorDataResponse.response;

        const shorediveDataRaw = await fetch(
            '/api/' + leaderboardType + '/division/shoredive',
        );
        const shorediveDataResponse = await shorediveDataRaw.json();
        const shorediveData = shorediveDataResponse.response;

        const data = openData
            .concat(womenData)
            .concat(juniorData)
            .concat(seniorData)
            .concat(shorediveData);
        console.log('combined open data:', data);
        setTopDogData(data);
    }, [leaderboardType]);

    useEffect(() => {
        if (divisionType === 'open') {
            fetchOpenData().catch(console.error);
        } 
        else if (divisionType === 'shoredive') {
            fetch('/api/allsubmissions/shoredive')
                .then((response) => response.json())
                .then((responsedata) => {
                    console.log(responsedata);
                    let shoreDiveData = responsedata.response;
                    setShoreDiveData(shoreDiveData);
                });
        }
        else if (divisionType !== 'team') {
            fetch('/api/' + leaderboardType + '/division/' + divisionType)
                .then((response) => response.json())
                .then((responsedata) => {
                    let topDogData = responsedata.response;
                    setTopDogData(topDogData);
                });
        } else {
            fetch('/api/' + leaderboardType)
                .then((response) => response.json())
                .then((responsedata) => {
                    let teamData = responsedata.response;
                    console.log('teamData:', teamData);
                    setTeamData(teamData);
                });
        }
    }, [fetchOpenData, leaderboardType, divisionType, filteredSubmissions]);

    function getMaxLength(data) {
        return data.reduce((prev, current) => {
            return prev.length > current.length ? prev : current;
        });
    }

    const getTeamsList = () => {
        const teams = [];
        const actualTeams = makeTeams();

        for (const property in actualTeams) {
            teams.push(actualTeams[property]);
        }
        console.log('teams: ', teams);
        teams.sort((a, b) => (a.teamTotal < b.teamTotal ? 1 : -1));
        return teams;
    };

    const getDiverTotal = (diver) => {
        let diverTotal = 0;
        let currentSpecies = masterSpeciesList.filter(
            (species) =>
                species.diveType === 'all' ||
                species.diveType === leaderboardType,
        );

        // eslint-disable-next-line no-loop-func
        currentSpecies.forEach(function (species) {
            var speciesName = species.value;

            if (
                diver[speciesName] !== null &&
                diver[speciesName] !== 0 &&
                diver[speciesName]
            ) {
                if (speciesName === 'lionfish') {
                    let lionfishCm = diver[speciesName] / 2.5;
                    lionfishCm = Math.round(lionfishCm * 2) / 2;
                    diverTotal = diverTotal + lionfishCm;
                } else {
                    diverTotal = diverTotal + diver[speciesName];
                }
            }
        });

        return diverTotal;
    };

    const makeTeams = () => {
        const actualTeams = {};
        for (const diver of teamData) {
            if (diver.team) {
                if (actualTeams[diver.team]) {
                    let diverTotal = getDiverTotal(diver);
                    actualTeams[diver.team]['diver_two'] =
                        diver.firstname + ' ' + diver.lastname;
                    actualTeams[diver.team]['diver_two_total'] = diverTotal;
                    actualTeams[diver.team]['teamTotal'] =
                        (actualTeams[diver.team]['teamTotal'] + diverTotal) / 2;
                } else {
                    actualTeams[diver.team] = {};
                    let diverTotal = getDiverTotal(diver);
                    actualTeams[diver.team]['diver_one'] =
                        diver.firstname + ' ' + diver.lastname;
                    actualTeams[diver.team]['diver_one_total'] = diverTotal;
                    actualTeams[diver.team]['teamTotal'] = diverTotal;
                }
            }
        }

        return actualTeams;
    };

    const getTopDogSponsor = () => {
        const dogs = TopDogSponsors.filter((sponsor) => {
            return (
                sponsor.divestyle === leaderboardType &&
                sponsor.divisionType === divisionType
            );
        });

        return dogs[0].title;
    };

    const getSubmissionsByOrderId = (id) => {
        return submissions.filter((submission, id) => {
            return submission.order === id;
        });
    };

    const getLeaderboardList = () => {
        const currentSpecies = masterSpeciesList.filter(
            (species) =>
                species.diveType === 'all' ||
                species.diveType === leaderboardType,
        );
        let leaders = [];
        currentSpecies.forEach((species) => {
            let subsBySpecies = getSubmissionsBySpecies(species.value);
            if (subsBySpecies.length > 0) {

                let biggestSubmission = getMaxLength(subsBySpecies);

                let topSub = {
                    firstname: biggestSubmission.firstname,
                    lastname: biggestSubmission.lastname,
                    length: biggestSubmission.length,
                    species: species.name,
                };

                leaders.push(topSub);
            } else {
                leaders.push({
                    firstname: '',
                    lastname: '',
                    length: 0,
                    species: species.name,
                });
            }
        });
        return leaders;
    };

    const getTopDogList = function () {
        let result = [];
        console.log('shoreDiveData:', shoreDiveData);
        console.log(divisionType);
        if(divisionType === 'shoredive') {
            let shoreDivers = {};
          /**   fetch('/api/allsubmissions/shoredive')
                .then((response) => response.json())
                .then((responsedata) => {
                    console.log(responsedata);
                    console.log('before for loop'); */
                    for(var i = 0; i < shoreDiveData.length; i++) {
                        console.log(shoreDiveData);
                         if(!shoreDivers[shoreDiveData[i].lastname]) {
                            shoreDivers[shoreDiveData[i].lastname] = {};
                         }
                         shoreDivers[shoreDiveData[i].lastname].firstname = shoreDiveData[i].firstname;
                         shoreDivers[shoreDiveData[i].lastname].order = shoreDiveData[i].order;
                         if(shoreDivers[shoreDiveData[i].lastname].hasOwnProperty(shoreDiveData[i].species)) {
                            // check if bigger
                            console.log('checking if bigger')
                            if(shoreDivers[shoreDiveData[i].lastname][shoreDiveData[i].species] < shoreDiveData[i].length) {
                                shoreDivers[shoreDiveData[i].lastname][shoreDiveData[i].species] = shoreDiveData[i].length;
                            }
                         } else {
                            // no species add one.
                            console.log('no species add one');
                          /**  if(!shoreDivers[shoreDiveData[i].lastname][shoreDiveData[i].species]){
                            shoreDivers[shoreDiveData[i].lastname][shoreDiveData[i].species] = {}
                           }*/
                            shoreDivers[shoreDiveData[i].lastname][shoreDiveData[i].species] = shoreDiveData[i].length;
                    
                         }
                    }
                    console.log(shoreDivers);
                    //create diver records

                    for (const property in shoreDivers) {
                        console.log(`${property}: ${shoreDivers[property]}`);

                        let diverTotal = 0;
                        let diverSpeciesCount = 0;
                        let currentSpecies = masterSpeciesList.filter(
                            (species) =>
                                species.diveType === 'all' ||
                                species.diveType === leaderboardType,
                        );

                        // eslint-disable-next-line no-loop-func
                        currentSpecies.forEach(function (species) {
                            var speciesName = species.value;

                            if (shoreDivers[property][speciesName] &&
                                shoreDivers[property][speciesName] !== undefined
                            ) 
                                diverSpeciesCount++;
                                console.log(shoreDivers[property][speciesName]);
                                if(shoreDivers[property][speciesName] !== undefined) {
                                    diverTotal = diverTotal + shoreDivers[property][speciesName];
                                }
                            }
                        );

                        let diver = {
                            name:
                            shoreDivers[property]['firstname'] +
                                ' ' +
                                property,
                            count: diverTotal,
                            speciesCount: diverSpeciesCount,
                            order: shoreDivers[property].order
                        };
        
                        result.push(diver);
                    }
                
        } else {
            //iterate through all of the divers.
            for (var i = 0; i < topDogData.length; i++) {
                let diverTotal = 0;
                let diverSpeciesCount = 0;
                let currentSpecies = masterSpeciesList.filter(
                    (species) =>
                        species.diveType === 'all' ||
                        species.diveType === leaderboardType,
                );

                // eslint-disable-next-line no-loop-func
                currentSpecies.forEach(function (species) {
                    var speciesName = species.value;

                    if (
                        topDogData[i][speciesName] !== null &&
                        topDogData[i][speciesName] !== 0 &&
                        speciesName !== 'lionfish_smallest'
                    ) {
                        diverSpeciesCount++;

                        if (speciesName === 'lionfish') {
                            let lionfishCm = topDogData[i][speciesName] / 2.5;
                            lionfishCm = Math.round(lionfishCm * 2) / 2;
                            diverTotal = diverTotal + lionfishCm;
                        } else {
                            diverTotal = diverTotal + topDogData[i][speciesName];
                        }
                    }
                });

                let diver = {
                    name:
                        topDogData[i]['firstname'] +
                        ' ' +
                        topDogData[i]['lastname'],
                    count: diverTotal,
                    speciesCount: diverSpeciesCount,
                    order: topDogData[i]['order'],
                };

                result.push(diver);
            }
        }
        result.sort(sortBySpeciesCount);
        return result;
    };

    let sortBySpeciesCount = function (diverA, diverB) {
        if (diverA.speciesCount < diverB.speciesCount) {
            return 1;
        } else if (diverA.speciesCount > diverB.speciesCount) {
            return -1;
        } else {
            if (diverA.count < diverB.count) {
                return 1;
            } else if (diverA.count > diverB.count) {
                return -1;
            }
        }
    };

    const getSubmissionsBySpecies = (speciesName) => {
        return filteredSubmissions.filter((submission) => {
            return submission.species === speciesName;
        });
    };

    const history = useHistory();
    const handleDiverRoute = (diverId) => {
        const submissions = getSubmissionsByOrderId(diverId);
        history.push({ pathname: '/diver/' + diverId, state: submissions });
    };

    if (leaderboardType === 'lionfish') {
        return null;
    }

    if (divisionType === 'team') {
        if (leaderboardType === 'freedive') {
            return (
                <div className="row">
                    <div className="col-sm-12 leaderboard-section">
                        <div className="leaderboard-title text-center">
                            <h5 className="text-center">Leaderboard</h5>
                        </div>
                        <div className="table-responsive col-md-12 leaderboard-table">
                            <table className="leaderboard-table table">
                                <thead>
                                    <tr style={{ color: '#235D93' }}>
                                        <th>Diver One</th>
                                        <th>Diver One Total</th>
                                        <th>Diver Two</th>
                                        <th>Diver Two Total</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Julie Higgs</td>
                                        <td>507</td>
                                        <td>Matthew Warner</td>
                                        <td>622.25</td>
                                        <td>564.75</td>
                                    </tr>
                                    <tr>
                                        <td>Ritchie Zacker</td>
                                        <td>511</td>
                                        <td>Charlie Domson</td>
                                        <td>351.25</td>
                                        <td>431.125</td>
                                    </tr>
                                    <tr>
                                        <td>Judah Clark</td>
                                        <td>534.25</td>
                                        <td>Jose Licardo</td>
                                        <td>254.5</td>
                                        <td>394.125</td>
                                    </tr>
                                    <tr>
                                        <td>Joseph Carter</td>
                                        <td>449.25</td>
                                        <td>Andrew Shottenstein</td>
                                        <td>72.75</td>
                                        <td>261</td>
                                    </tr>
                                    <tr>
                                        <td>Jamie Mccoullugh</td>
                                        <td>0</td>
                                        <td>Cooper Baker</td>
                                        <td>345.5</td>
                                        <td>172.75</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-sm-12 leaderboard-section">
                        <div className="leaderboard-title text-center">
                            <h5 className="text-center">Leaderboard</h5>
                        </div>
                        <div className="table-responsive col-md-12 leaderboard-table">
                            <table className="leaderboard-table table">
                                <thead>
                                    <tr style={{ color: '#235D93' }}>
                                        <th>Diver One</th>
                                        <th>Diver One Total</th>
                                        <th>Diver Two</th>
                                        <th>Diver Two Total</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Kelly Reeder</td>
                                        <td>528.5</td>
                                        <td>Lonnie Reeder</td>
                                        <td>583</td>
                                        <td>555.75</td>
                                    </tr>
                                    <tr>
                                        <td>Josh Weaver</td>
                                        <td>480.5</td>
                                        <td>Hunter Weaver</td>
                                        <td>397.75</td>
                                        <td>439.25</td>
                                    </tr>
                                    <tr>
                                        <td>Aric Greene</td>
                                        <td>457.75</td>
                                        <td>Bo Glover</td>
                                        <td>324</td>
                                        <td>390.875</td>
                                    </tr>
                                    <tr>
                                        <td>Shawn Durrance</td>
                                        <td>95</td>
                                        <td>Chad Tripp</td>
                                        <td>0</td>
                                        <td>47.5</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }

    if (divisionType !== 'team') {
        return (
            <div className="row">
                <div className="col-sm-6 leaderboard-section">
                    <div className="leaderboard-title text-center">
                        <h5 className="text-center">Leaderboard</h5>
                    </div>
                    <div className="table-responsive col-md-12 leaderboard-table">
                        <table className="leaderboard-table table">
                            <thead>
                                <tr style={{ color: '#235D93' }}>
                                    <th>Sponsor</th>
                                    <th>Species</th>
                                    <th>Name</th>
                                    <th>Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getLeaderboardList().map((row, i) => {
                                    return (
                                        <LeaderboardRow
                                            key={i}
                                            firstname={row.firstname}
                                            leaderboardType={leaderboardType}
                                            lastname={row.lastname}
                                            species={row.species}
                                            length={row.length}
                                            setModalSponsor={setModalSponsor}
                                        ></LeaderboardRow>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-6 leaderboard-section">
                    <div className="leaderboard-title text-center">
                        <h5
                            className="text-center"
                            data-toggle="modal"
                            data-target="#sponsorModal"
                            ng-click="setModalSponsor(topDogSponsor)"
                        >
                            Top Dog by{' '}
                            <span style={{ color: '#337ab7' }}>
                                {getTopDogSponsor()}
                            </span>
                        </h5>
                    </div>
                    <div className="table-responsive col-md-12 leaderboard-tablefafafacecod">
                        <table className="leaderboard-table table">
                            <thead>
                                <tr style={{ color: '#235D93' }}>
                                    <th>Rank</th>
                                    <th>Name</th>
                                    <th># of Species</th>
                                    <th>Total Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getTopDogList().map((row, index) => {
                                    return (
                                        <TopDogRow
                                            key={index}
                                            count={row.count}
                                            speciesCount={row.speciesCount}
                                            name={row.name}
                                            rank={index}
                                            onDiverClick={() =>
                                                handleDiverRoute(row.order)
                                            }
                                        ></TopDogRow>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <SponsorModal sponsor={modalSponsor}></SponsorModal>
            </div>
        );
    }
};

export default LeaderboardMain;

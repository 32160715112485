const masterSpeciesList = [
    {
        name: 'Amberjack',
        value: 'amberjack',
        diveType: 'all',
    },
    {
        name: 'African Pompano',
        value: 'africanpompano',
        diveType: 'all',
    },
    {
        name: 'Cobia',
        value: 'cobia',
        diveType: 'all',
    },
    {
        name: 'Cubera',
        value: 'cubera',
        diveType: 'all',
    },
    {
        name: 'Black Grouper',
        value: 'blackgrouper',
        diveType: 'scuba',
    },
    {
        name: 'Gag Grouper',
        value: 'gaggrouper',
        diveType: 'scuba',
    },
    {
        name: 'Red Grouper',
        value: 'redgrouper',
        diveType: 'scuba',
    },
    {
        name: 'Grouper',
        value: 'grouper',
        diveType: 'freedive',
    },
    {
        name: 'Hogfish',
        value: 'hogfish',
        diveType: 'all',
    },
    {
        name: 'Kingfish',
        value: 'kingfish',
        diveType: 'freedive',
    },
    {
        name: 'Mahi',
        value: 'mahi',
        diveType: 'freedive',
    },
    {
        name: 'Mangrove Snapper',
        value: 'mangrove',
        diveType: 'all',
    },
    {
        name: 'Mutton Snapper',
        value: 'mutton',
        diveType: 'all',
    },
    {
        name: 'Rainbow Runner',
        value: 'rainbowrunner',
        diveType: 'all'
    },
    {
        name: 'Red Snapper',
        value: 'redsnapper',
        diveType: 'all',
    },
    {
        name: 'Sheepshead',
        value: 'sheepshead',
        diveType: 'all',
    },
    {
        name: 'Stingray',
        value: 'stingray',
        diveType: 'all'
    },
    {
        name: 'Permit',
        value: 'permit',
        diveType: 'all',
    },
    {
        name: 'Wahoo',
        value: 'wahoo',
        diveType: 'freedive',
    },
    {
        name: 'Scamp Grouper',
        value: 'scamp',
        diveType: 'scuba',
    },
    {
        name: 'Triggerfish',
        value: 'triggerfish',
        diveType: 'scuba',
    },
    {
        name: 'Tuna',
        value: 'tuna',
        diveType: 'freedive',
    },
    {
        name: 'Yellowjack',
        value: 'yellowjack',
        diveType: 'all',
    },
    {
        name: 'Yellowtail Snapper',
        value: 'yellowtail',
        diveType: 'freedive',
    },
    {
        name: 'Lobster',
        value: 'lobster',
        diveType: 'all',
    },
    {
        name: 'Lionfish Biggest',
        value: 'lionfish',
        diveType: 'all',
    },
];

export default masterSpeciesList;

import React from 'react';

const Rules = () => {
    return (
        <div>
            <section id="page" class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h2>Rules</h2>
                        </div>
                    </div>
                </div>
            </section>

            <div id="about" class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="section-title text-center">
                            <h2>2024 Florida Spearfishing Tournament Rules</h2>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <p class="rulesSection">
                                <ul>
                                    <li>
                                        <b>
                                            Start/End Dates: Tournament Starts
                                            June 1st, 2024, ending on December
                                            April 1st, 2025.
                                        </b>
                                    </li>
                                    <li>
                                        Rules are subject to change. Any changes
                                        will be posted on the website and
                                        Facebook group. It is up to the
                                        participant to know the rules before you
                                        enter fish.
                                    </li>
                                    <li>
                                        <b>
                                            $90 Registration Fee - Shooters
                                            Package includes:
                                        </b>
                                        <ul>
                                            <li>
                                                FL Spearfishing Tape Measure
                                            </li>
                                            <li>
                                                FL Spearfishing Hat (Subsidized
                                                by Speared Gear)
                                            </li>
                                            <li>
                                                FL Spearfishing Shirt
                                                (Subsidized by Speared Gear)
                                            </li>
                                            <li>Neritic Diving Gloves</li>
                                        </ul>
                                    </li>

                                    <li>
                                        The award ceremony will take place in
                                        January, Time and location TBD
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <hr></hr>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <p class="rulesSection">
                                <h4>SUBMITTING FISH</h4>
                                <ul>
                                    <li>
                                        All submissions must be submitted onto
                                        the Facebook group and the website no
                                        later than 7 days after the fish is
                                        harvested.
                                    </li>
                                    <li>
                                        You must spear and land your fish with a
                                        witness to the catch.
                                    </li>
                                    <li>
                                        Commercially speared fish are allowed.
                                        However, only fish that are also open to
                                        the recreational diver in the area where
                                        fish are being harvested can be entered.
                                        For example, if Gag Grouper is closed
                                        for the recreational diver, a commercial
                                        Gag Grouper cannot be entered until the
                                        season opens in the area the fish is
                                        harvested.
                                    </li>
                                    <li>
                                        All fisheries laws and regulations must
                                        be complied with for the area the fish
                                        are captured. Breach of any fisheries
                                        laws and regulations, or suspected
                                        breach, will be sufficient ground for
                                        disqualification.
                                    </li>
                                    <li>
                                        Only fish landed in Florida may be
                                        entered. Fish cannot be entered from
                                        Bahamian waters. This means the boat
                                        must leave from a dock in Florida, and
                                        you must return to a dock in Florida.
                                    </li>
                                    <li>
                                        Take multiple photos or videos of the
                                        fish that clearly shows the length of
                                        the fish. Take many photos and videos
                                        from numerous angles to help protect
                                        yourself and your entry in the event of
                                        an official length/measurement
                                        dispute/challenge.
                                    </li>
                                    <li>
                                        All measurements must start at the fork
                                        of the tail, with the tape measure
                                        pulled tight across the body to the
                                        nose. There must be hands, dive weights,
                                        clips or some mechanism holding the tape
                                        measure tight from the tail to the nose
                                        of the fish, not allowing for any
                                        wrinkles or “dripping” of tape measure.
                                        Photos must be taken from directly above
                                        the fish. Videos should start at the
                                        tail and finish over the mouth, with the
                                        entire measurement of the fish in frame
                                        showing the requirements during a
                                        measure. ALL FISH MOUTHS MUST BE
                                        RESTING. Hogfish cannot be measured with
                                        a protruding snout. Large groupers must
                                        show a sufficient effort to close gill
                                        plates and mouth to avoid length
                                        extension during measurements. (We
                                        understand that rigimortis takes place
                                        when properly iced)
                                    </li>
                                    <li>
                                        Fish are measured to the closest quarter
                                        inch (round down to avoid conflict) and
                                        must beat previous fish by at least a
                                        quarter inch to overtake its spot on the
                                        leader board.
                                    </li>
                                    <li>
                                        The head must be completely connected to
                                        the body. If the “throat” is
                                        disconnected as a result of spearing,
                                        the head must be placed back in line
                                        with the body. If there is a disconnect
                                        visible in the submitted photos, the
                                        admins will review and determine if a
                                        penalty is to be assessed.
                                    </li>
                                    <li>
                                        -Dishonest or improper measurements will
                                        result in an administration vote for
                                        measurement correction in addition to
                                        additional corrective action leading up
                                        to permanent expulsion from the
                                        tournament.
                                    </li>
                                    <li>
                                        To officially submit your entry you must
                                        use the online form under the
                                        submissions tab on the
                                        flspearfishing.com website and follow
                                        instructions to submit. You must also
                                        share your catch on the
                                        facebook.com/groups/FloridaSpearfishingTournament/
                                        page including all of the information
                                        used to submit on the website (date,
                                        witness, species, length, order number,
                                        and division).
                                    </li>
                                    <li>
                                        Participants can enter fish in both
                                        Freedive and SCUBA categories.
                                    </li>
                                    <li>
                                        Participants can only win one category
                                        in each division. If a participant
                                        shoots the largest fish in multiple
                                        categories, the participant must choose
                                        which category they win.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <hr></hr>
                            <p class="rulesSection">
                                <h4>TEAM CALCUTTA</h4>
                                <ul>
                                    <li>
                                        Team Calcutta: Two-person team, with
                                        team points being an AVERAGE of the two
                                        shooters aggregate length of target
                                        species. The entry cost for the team is
                                        200$ per team. The top 5 leaderboard
                                        from 2020 will not be allowed to team up
                                        with each other.
                                    </li>
                                    <li>
                                        Team Calcutta cash payouts for the Top 5
                                        Teams in both Freedive and SCUBA
                                        divisions with payout dependent on
                                        number of people entered.
                                    </li>
                                    <li>
                                        Team Calcutta Payout Structure: 1st
                                        Place (40%), 2nd Place (20%), 3rd Place
                                        (10%), 4th Place (5%), 5th Place (5%),
                                        House Takes 20%.
                                    </li>
                                    <li></li>
                                </ul>
                            </p>
                            <hr></hr>
                            <p class="rulesSection">
                                <h4>TOP DOG</h4>
                                <ul>
                                    <li>
                                        1) Participant with the longest length
                                        of fish at the end of the year will be
                                        crowned Florida Spearfishing's TOP DOG.
                                        However, the most species outweighs
                                        total length. Example: A participant
                                        with an overall length of 120 inches is
                                        in the lead with 4 different species of
                                        fish. Someone can take the lead with a
                                        smaller overall length, but 5 species.
                                        The participant with the most species
                                        will always win. If there are multiple
                                        participants who have submitted all
                                        species, the winner will be based on the
                                        total length!
                                    </li>
                                    <li>
                                        2) Post pictures the same way you would
                                        for any other category, on the website
                                        and the Facebook group.
                                    </li>
                                    <li>
                                        3) Participant with the longest total
                                        length of fish is the winner.
                                    </li>
                                    <li>
                                        4) Only one fish from each species will
                                        count toward an individuals Top Dog
                                        total length.
                                    </li>
                                    <li>
                                        5) Rules for the TOP DOG category are
                                        the same as regular entries. There will
                                        be a separate TOP DOG for Freedive AND
                                        SCUBA for the open, Women's and Junior
                                        Categories!
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rules;

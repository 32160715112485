import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ movie }) => {
    return (
        <footer>
            <div className="footer-top footerbg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12 widget">
                            <div className="section-title col-sm-12 no-padding">
                                <a href="index.html">
                                    <h1>Florida Spearfishing</h1>
                                </a>
                            </div>
                            <div className="about">
                                <p>
                                    The Florida Spearfishing Tournament is an
                                    event that allows people from all over the
                                    state of Florida to compete! Spearfish all
                                    the time like you normally do, except now
                                    when you get that huge fish, enter it to win
                                    prizes or to become the TOP DOG! There are
                                    many categories, but you can only be the
                                    leader in one. Choose wisely!
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 widget pull-right">
                            <div className="section-title col-sm-12 no-padding">
                                <h6>DIVE SAFE</h6>
                                <h2 className="section-title-white">
                                    Quick Links
                                </h2>
                            </div>
                            <ul className="quick-link-list">
                                <li>
                                    <Link to="/rules">Rules</Link>
                                </li>
                                <li>
                                    <Link to="/leaderboard">Leaderboard</Link>
                                </li>
                                <li>
                                    <Link to="/submission">Submit a Fish</Link>
                                </li>
                                <li>
                                    <a href="https://florida-spearfishing.myshopify.com/products/2024-individual-florida-spearfishing-registration">
                                        Registration
                                    </a>
                                </li>
                                <li>
                                    <Link to="/sponsors">Sponsors</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright footerbg-3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 copyright-text">
                            <p>
                                Copyright &copy; 2024{' '}
                                <a href="#"> Florida Spearfishing.</a> | All
                                Rights Reserved
                            </p>
                        </div>
                        <div className="col-sm-6 footer-menu text-right">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/groups/382526771773464/">
                                        facebook
                                    </a>{' '}
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/floridaspearfishing/">
                                        instagram
                                    </a>{' '}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

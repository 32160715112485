import './App.css';
import Footer from './components/footer/footer';
import Preloader from './components/Preloader';
import Header from './components/Header';
import Homepage from './components/Homepage';
import Rules from './containers/Rules';
import Sponsors from './containers/Sponsors';
import Admin from './containers/Admin';
import SubmissionsTable from './containers/SubmissionsTable/SubmissionsTable';
import Leaderboard from './containers/Leaderboard/Leaderboard.js';
import SubmissionPage from './containers/SubmissionPage/SubmissionPage';
import Diver from './containers/DiverPage/Diver';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
function App() {
    let hashHistory = Router.hashHistory;
    return (
        <Router history={hashHistory}>
            <div className="App">
                <Preloader></Preloader>
                <Header></Header>
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route path="/leaderboard" component={Leaderboard} />
                    <Route path="/rules" component={Rules} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/submission" component={SubmissionPage} />
                    <Route path="/submissions" component={SubmissionsTable} />
                    <Route path="/sponsors" component={Sponsors} />
                    <Route path="/diver/:diverId" component={Diver} />
                    <Route component={Homepage} />
                </Switch>
                <Footer></Footer>
            </div>
        </Router>
    );
}

export default App;

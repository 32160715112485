import React from 'react';
import { Link } from 'react-router-dom';

const TopBar = () => {
    const state = {
        showSocial: false,
    };

    const SocialBar = () => {
        return (
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="social">
                    <ul className="clearfix">
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-youtube"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-pinterest"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-dribbble"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i className="fa fa-google-plus"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <div className="top-area">
            <div className="container">
                <div className="row">
                    {state.showSocial && <SocialBar></SocialBar>}

                    <div className="col-md-8 col-sm-12 col-xs-12">
                        <div className="info-menu">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-phone"></i>+
                                        123-456-7890
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@domain.com">
                                        <i className="fa fa-envelope-o"></i>{' '}
                                        info@flspearfishing.com
                                    </a>
                                </li>
                                <li>
                                    <a href="https://flspearfishing.com/products/florida-spearfishing-registration">
                                        <i className="fa fa-user-o"></i>{' '}
                                        Register
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Header = () => {
    const state = {
        showTopBar: false,
    };
    return (
        <header className="header-1">
            {state.showTopBar && <TopBar></TopBar>}
            <div className="logo-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                            <div className="logo">
                                <a href="index.html">
                                    <img src="images/logo.png"></img>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 hidden-sm hidden-xs">
                            <div className="logo-area-right"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-menu">
                <div className="mainmenu-area hidden-xs">
                    <div className="navbar navbar-default">
                        <div className="container">
                            <nav>
                                <ul className="nav navbar-nav navbar-left">
                                    <li className="dropdown mega-dropdown">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/rules">Rules</Link>
                                    </li>
                                    <li>
                                        <Link to="/leaderboard">
                                            Leaderboard
                                        </Link>
                                    </li>
                                    <li className="dropdown">
                                        <Link to="/submissions">
                                            Submissions
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/submissions">
                                                    Submissions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/submission">
                                                    Submit a Fish
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/sponsors">
                                            Sponsors and Prizes
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://florida-spearfishing.myshopify.com/products/2024-individual-florida-spearfishing-registration">
                                            Registration
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-area hidden-lg hidden-md hidden-sm">
                    <div className="col-md-7">
                        <div className="mobile-menu">
                            <nav id="dropdown">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/rules">Rules</Link>
                                    </li>
                                    <li>
                                        <Link to="/leaderboard">
                                            Leaderboard
                                        </Link>
                                    </li>
                                    <li className="dropdown">
                                        <a>Submissions</a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/submissions">
                                                    Submissions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/submission">
                                                    Submit a Fish
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/sponsors">
                                            Sponsors and Prizes
                                        </Link>
                                    </li>
                                    <li>
                                        <a>Register</a>
                                        <ul>
                                            <li>
                                                <a href="https://florida-spearfishing.myshopify.com/products/2024-individual-florida-spearfishing-registration">
                                                    Register Individual
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://florida-spearfishing.myshopify.com/products/team-2024-florida-spearfishing-registration">
                                                    Register Team
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;

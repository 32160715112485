import React, { useEffect, useState } from 'react';
import masterSpeciesList from '../../constants';
const TopFive = (props) => {
    let { leaderboardType, divisionType, submissions } = props;

    const [speciesList, setSpeciesList] = useState(masterSpeciesList);
    const [selectedSpecies, setSelectedSpecies] = useState(
        masterSpeciesList[0].value,
    );
    useEffect(() => {
        let currentSpecies = masterSpeciesList.filter(
            (species) =>
                species.diveType === 'all' ||
                species.diveType === leaderboardType,
        );
        setSpeciesList(currentSpecies);
    }, [props.leaderboardType]);
    const getTopFiveBySpecies = (species) => {
        //filter species
        let speciesOnly = submissions.filter((submission) => {
            return submission.species == species;
        });

        //sort
        speciesOnly.sort((a, b) => (a.length < b.length ? 1 : -1));

        //grab top 5
        let topFive = speciesOnly.splice(0, 5);
        return topFive;
    };

    const changeSpecies = (e) => {
        setSelectedSpecies(e.target.value);
    };
    if (leaderboardType === 'lionfish' || divisionType === 'team') {
        return null;
    }
    return (
        <div
            className="col-sm-12"
            ng-hide="leaderboardCategory === 'Lionfish' || team"
        >
            <div className="leaderboard-title text-center">
                <h5>Top Five</h5>
                <select
                    style={{ width: '33%', margin: '0 auto' }}
                    name="topTenSpecies"
                    className="form-control"
                    id="topTenSpecies"
                    onChange={(e) => changeSpecies(e)}
                >
                    {speciesList.map((species) => (
                        <option value={species.value}>{species.name}</option>
                    ))}
                </select>
            </div>
            <div className="table-responsive col-md-12">
                <table className="leaderboard-table table">
                    <thead>
                        <tr style={{ color: '#235D93' }}>
                            <th>#</th>
                            <th>Name</th>
                            <th>Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTopFiveBySpecies(selectedSpecies).map(
                            (submission, index) => {
                                return (
                                    <tr ng-repeat="diver in topTenData">
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            {submission.firstname}{' '}
                                            {submission.lastname}
                                        </td>
                                        <td>{submission.length}</td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TopFive;

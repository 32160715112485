import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import masterSpeciesList from '../../constants';

const SpeciesSelect = ({ control, register, errors }) => {
    const diveStyle = useWatch({
        control,
        name: 'diveStyle', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: 'freedive', // default value before the render
    });

    let speciesOptions = null;

    if (diveStyle) {
        speciesOptions = masterSpeciesList
            .filter((species) => {
                return (
                    species.diveType === diveStyle || species.diveType === 'all'
                );
            })
            .map((species) => (
                <option key={species.value} value={species.value}>
                    {species.name}
                </option>
            ));
    }

    return (
        <div
            className={`form-group col-md-4 ${
                errors.species ? 'has-error' : ''
            }`}
        >
            <label>Species</label>
            <select
                name="species"
                className="form-control"
                id="species"
                ref={register({
                    required: true,
                })}
            >
                {speciesOptions}
            </select>

            {errors.species && (
                <span style={{ color: 'darkred' }}>Must select a species.</span>
            )}
        </div>
    );
};

const SubmissionPage = () => {
    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: {},
    });

    const sanitizeData = (data) => {
        console.log('unsanitized data:', data);
        const sanitizedLength = data.length.replace(/['"]+/g, '');
        const sanitizedData = {
            firstname: data.firstName,
            lastname: data.lastName,
            species: data.species,
            order: data.order,
            length: sanitizedLength,
            divestyle: data.diveStyle,
            division: data.division,
            fishStory: data.fishStory,
        };
        console.log('sanitized data:', sanitizedData);
        return sanitizedData;
    };

    const onSubmit = (data) => {
        console.log('submitting: ', data);
        const postData = sanitizeData(data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };

        console.log(requestOptions);

        fetch('/api/allsubmissions', requestOptions)
            .then((response) => response.json())
            .then((responsedata) => {
                console.log(responsedata);

                if (responsedata.success) {
                    const species = responsedata.result.species;
                    const length = responsedata.result.length;
                    toast('Submission successul: ' + length + ' | ' + species);
                } else {
                    toast('Something went wrong submitting');
                }
            });
    };

    const FirstNameInput = () => {
        return (
            <div
                className={`form-group col-md-4 ${
                    errors.firstName ? 'has-error' : ''
                }`}
            >
                <label htmlFor="firstName">First Name</label>
                <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="first-name"
                    ref={register({ required: true, maxLength: 20 })}
                ></input>
                {errors.firstName && (
                    <span style={{ color: 'darkred' }}>
                        First Name is required.
                    </span>
                )}
            </div>
        );
    };

    const LastNameInput = () => {
        return (
            <div
                className={`form-group col-md-4 ${
                    errors.lastName ? 'has-error' : ''
                }`}
            >
                <label>Last Name</label>
                <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="last-name"
                    ref={register({ required: true })}
                ></input>
                {errors.lastName && (
                    <span style={{ color: 'darkred' }}>
                        Last Name is required.
                    </span>
                )}
            </div>
        );
    };

    const OrderInput = () => {
        return (
            <div
                className={`form-group col-md-2 ${
                    errors.order ? 'has-error' : ''
                }`}
            >
                <label>Order #</label>
                <input
                    name="order"
                    className="form-control"
                    id="order"
                    ref={register({ required: true })}
                ></input>
                {errors.order && (
                    <span style={{ color: 'darkred' }}>
                        Order # is required.
                    </span>
                )}
            </div>
        );
    };
    const LengthInput = () => {
        return (
            <div
                className={`form-group col-md-2 ${
                    errors.length ? 'has-error' : ''
                }`}
            >
                <label> Length </label>
                <input
                    type="double"
                    name="length"
                    className="form-control"
                    id="length"
                    ref={register({ required: true })}
                ></input>
                {errors.length && (
                    <div style={{ color: 'darkred' }}>
                        Length or Count is required.
                    </div>
                )}
            </div>
        );
    };

    const DivisionSelect = () => {
        return (
            <div
                className={`form-group col-md-4 ${
                    errors.division ? 'has-error' : ''
                }`}
            >
                <label>Division</label>
                <select
                    name="division"
                    className="form-control"
                    id="division"
                    ref={register({ required: true })}
                >
                    <option value="open">Open</option>
                    <option value="women">Women</option>
                    <option value="junior">Junior</option>
                    <option value="senior">Senior</option>
                    <option value="shoredive">Shore Dive</option>
                </select>
                {errors.division && (
                    <span style={{ color: 'darkred' }}>
                        Must select a division.
                    </span>
                )}
            </div>
        );
    };

    return (
        <div>
            <section id="page" className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 text-left">
                            <h2>Submit</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div id="contactFrom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                            <div className="contact">
                                <form
                                    id="contact-form"
                                    className="form"
                                    name="submissionForm"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="row">
                                        <FirstNameInput></FirstNameInput>
                                        <LastNameInput></LastNameInput>
                                        <OrderInput></OrderInput>
                                        <LengthInput></LengthInput>
                                        <div
                                            className={`form-group col-md-4 ${
                                                errors.diveStyle
                                                    ? 'has-error'
                                                    : ''
                                            }`}
                                        >
                                            <label>Dive Style</label>
                                            <select
                                                name="diveStyle"
                                                className="form-control"
                                                ref={register({
                                                    required: true,
                                                })}
                                            >
                                                <option value="freedive">
                                                    Freedive
                                                </option>
                                                <option value="scuba">
                                                    Scuba
                                                </option>
                                            </select>
                                            {errors.diveStyle && (
                                                <span
                                                    style={{ color: 'darkred' }}
                                                >
                                                    Must select your dive
                                                    method.
                                                </span>
                                            )}
                                        </div>
                                        <SpeciesSelect
                                            control={control}
                                            register={register}
                                            errors={errors}
                                        ></SpeciesSelect>
                                        <DivisionSelect></DivisionSelect>
                                        <div className="form-group col-md-12">
                                            <label>About the fish</label>
                                            <textarea
                                                rows="8"
                                                name="fishStory"
                                                className="form-control"
                                                id="description"
                                                ref={register({})}
                                            ></textarea>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="btn btn-default btn-file">
                                                <input
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    file-model="files"
                                                    multiple
                                                />
                                                <div className="button">
                                                    Upload Picture
                                                </div>
                                            </label>
                                            <br></br>
                                            <p>Pictures:</p>
                                        </div>
                                        <div className="form-group col-md-4 mb0">
                                            <div className="actions">
                                                <input
                                                    type="submit"
                                                    value="Submit Fish"
                                                    className="btn btn-lg btn-contact-bg"
                                                    title="Click here to submit your fish!"
                                                />
                                                <img
                                                    src="assets/img/ajax-loader.gif"
                                                    width="16"
                                                    height="16"
                                                    id="loader"
                                                    style={{ display: 'none' }}
                                                    alt="loading"
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                closeOnClick
            ></ToastContainer>
            <script type="text/ng-template" id="popupTmpl">
                <div className="ngdialog-message">
                    <h3>Fish submitted!</h3>
                    <p>
                        An admin is reviewing everything. When your submission
                        is approved your results will show up in the
                        leaderboard.{' '}
                    </p>
                </div>
            </script>
            ]
        </div>
    );
};

export default SubmissionPage;

import React from 'react';

const Homepage = () => {
    return (
        <div>
            <div id="about" class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="section-title text-center">
                            <h2>Welcome</h2>
                            <div class="line"></div>
                            <p>
                                The Florida Spearfishing Tournament is an event
                                that allows people from all over the state of
                                Florida to compete! Spearfish all the time like
                                you normally do, except now when you get that
                                huge fish, enter it to win prizes or to become
                                the TOP DOG! There are many categories, but you
                                can only be the leader in one. Choose wisely!
                                Use the links above to find out how to register,
                                the rules, or the prizes. Good luck!
                            </p>
                        </div>
                    </div>
                    <div class="text-center slider-style-table">
                        <div class="title-container slider-style-table-cell">
                            <span class="sbutton-link">
                                <a
                                    href="https://florida-spearfishing.myshopify.com/products/2024-individual-florida-spearfishing-registration"
                                    class="btn slider-btn"
                                >
                                    Register <i class="fa fa-caret-right"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;

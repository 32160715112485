import React from 'react';

const LionfishLeaderboard = (props) => {
    let { leaderboardType, submissions, lionfishType } = props;

    const getRecords = () => {
        if (lionfishType === 'smallest') {
            return getSmallest();
        }

        if (lionfishType === 'biggest') {
            return getLargest();
        }

        if (lionfishType === 'most') {
            return getCount();
        }
    };
    const getSmallest = () => {
        const smallestLionfishSubs = submissions.filter((sub) => {
            return sub.species === 'lionfish_smallest' && sub.approved === 1;
        });

        const divers = {};

        smallestLionfishSubs.forEach(function (sub) {
            if (divers[sub.order]) {
                if (divers[sub.order].length > sub.length) {
                    divers[sub.order] = sub;
                }
            } else {
                divers[sub.order] = sub;
            }
        });

        const smallestLionfishDivers = [];
        for (const prop in divers) {
            if (divers.hasOwnProperty(prop)) {
                smallestLionfishDivers.push(divers[prop]);
            }
        }

        return smallestLionfishDivers;
    };

    const getLargest = () => {
        const largestLionfishSubs = submissions.filter((sub) => {
            return sub.species === 'lionfish' && sub.approved === 1;
        });

        const divers = {};

        largestLionfishSubs.forEach(function (sub) {
            if (divers[sub.order]) {
                if (divers[sub.order].length < sub.length) {
                    divers[sub.order] = sub;
                }
            } else {
                divers[sub.order] = sub;
            }
        });

        const largestLionfishDivers = [];
        for (const prop in divers) {
            if (divers.hasOwnProperty(prop)) {
                largestLionfishDivers.push(divers[prop]);
            }
        }
        console.log(largestLionfishDivers);
        largestLionfishDivers.sort((a, b) => (b.length > a.length ? 1 : -1));
        return largestLionfishDivers;
    };

    const getCount = () => {
        const mostLionfishSubs = submissions.filter((sub) => {
            return sub.species === 'lionfish_count' && sub.approved === 1;
        });

        const divers = {};

        mostLionfishSubs.forEach(function (sub) {
            if (divers[sub.order]) {
                if (divers[sub.order].length > sub.length) {
                    divers[sub.order] = sub;
                }
            } else {
                divers[sub.order] = sub;
            }
        });

        const smallestLionfishDivers = [];
        for (const prop in divers) {
            if (divers.hasOwnProperty(prop)) {
                smallestLionfishDivers.push(divers[prop]);
            }
        }

        return [];
    };

    const LionfishLeaderboardRow = (props) => {
        const { firstname, lastname, size, lionfishType } = props;
        return (
            <tr ng-repeat="lionfish in lionfishData">
                <td>
                    {firstname} {lastname}
                </td>
                <td>
                    {size} {lionfishType === 'most' ? '' : 'cm'}
                </td>
            </tr>
        );
    };
    if (leaderboardType === 'lionfish') {
        return (
            <div className="col-sm-12 leaderboard-section">
                <div className="leaderboard-title text-center lionfish-title">
                    <h5 className="text-center">
                        Presented by{' '}
                        <span style={{ color: '#337ab7;' }}>
                            Neritic Diving
                        </span>
                    </h5>
                </div>
                <div className="table-responsive col-md-12">
                    <table className="leaderboard table">
                        <thead>
                            <tr style={{ color: '#235D93;' }}>
                                <th>Name</th>
                                <th>Size/Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getRecords().map((row, index) => {
                                return (
                                    <LionfishLeaderboardRow
                                        firstname={row.firstname}
                                        lastname={row.lastname}
                                        size={row.length}
                                        lionfishType={lionfishType}
                                    ></LionfishLeaderboardRow>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default LionfishLeaderboard;

import React from "react";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loadscreen">
        <div className="loadscreen-in">
          <h4>Florida Spearfishing</h4>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
